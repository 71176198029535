/**
* Generated automatically at built-time (2024-10-07T17:07:18.199Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "utekok-fristaende-moduler",templateKey: "sites/99-7b596768-2969-4ece-a1bd-2a09ffd244d9"};